<template>
  <div>
    <app-header />

    <section class="gifting-services container">
      <section class="gifting-services__section">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <h1 class="gifting-services__title">
              Custom gifting<br />services for<br />
              on-demand delivery
            </h1>

            <p class="gifting-services__text">
              Order a thoughtful gift for any occasion and we will prepare and
              deliver it promptly. Whether you prefer the gift be delivered to
              your default address or directly to the intended recipient, we
              have you covered. For your convenience, we offer the option to add
              an additional delivery address to a gift during checkout, allowing
              for delivery to more than one address per order.<br /><br />
              Please contact our customer support team for questions about our
              gifting services or to make a corporate or special event request.
            </p>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-carousel
              indicator-position="outside"
              :height="isDesktop ? '650px' : '350px'"
              :interval="4000"
            >
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_1.png" />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_2.png" />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_3.png" />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_4.png" />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_5.png" />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_6.png" />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_7.png" />
                </div>
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
      </section>

      <section class="gifting-services__section">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <img src="../assets/gifts_1.png" />
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="gifting-services__to-right">
              <h1 class="gifting-services__title">
                Order directly through<br />
                the RoadRunner<br />
                mobile app
              </h1>

              <p class="gifting-services__text">
                With customizable gifting options available inside the app, you
                have the flexibility to place a single order inclusive of
                multiple retailers and choose which merchandise you would like
                to gift.<br /><br />
                Prices for gift wrapping and greeting cards are standard for all
                orders and are listed in the app.
              </p>

              <div class="gifting-services__download-buttons">
                <img
                  class="gifting-services__download"
                  src="../assets/dow_apple.png"
                />
                <img
                  class="gifting-services__download"
                  src="../assets/dow_google.png"
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </section>

      <section class="gifting-services__section">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <h1 class="gifting-services__title">
              Luxury<br />
              gift wrap
            </h1>

            <p class="gifting-services__text">
              Our elegant gift wrap is supplied by Fleur Wholesale, a Canadian
              small business propelling high end décor. To better suite our
              customer’s needs, we offer neutrally coloured gift wrap which is
              high quality and modern. Our eco-friendly black kraft paper bags
              are thick and durable, have a soft fabric handle, come in
              different sizes to snugly fit your gift, and are paired with our
              water-resistant white tissue paper which is textured and soft to
              the touch. Our waterproof camel and coffee coloured double sided
              wrapping paper is thick in texture, smooth to the touch, has a
              clean matte finish, and is paired with our rustic textured grey
              fabric ribbon, tied into an elegant bow.<br /><br />
              Depending on the nature, size and weight of an order, we will
              appropriately wrap the gift. Please note that oversized or heavy
              items may be unable to be gift wrapped. If an order has been
              placed that is not suitable for gift wrapping, the customer will
              be notified and reimbursed for the cost of the gifting services.
            </p>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div v-if="isDesktop">
              <br /><br />
              <br /><br />
              <br /><br />
              <br /><br />
              <br /><br />
              <br /><br />
            </div>

            <el-carousel
              indicator-position="outside"
              :height="isDesktop ? '400px' : '230px'"
              :interval="4000"
            >
              <el-carousel-item>
                <img src="../assets/car2_1.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/car2_2.png" />
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
      </section>

      <section class="gifting-services__section">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <br /><br />
            <br /><br />
            <br /><br />
            <el-carousel
              indicator-position="outside"
              :height="isDesktop ? '550px' : '350px'"
              :interval="4000"
            >
              <el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Birthday.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Anniversary.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Congratulations.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Best Wishes.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/GetWell.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Sympathy.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/ThankYou.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img
                  src="../assets/Engagement & Wedding.png"
                /> </div></el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Shower&NewBaby.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Valentines.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Season'sGreetings.png" /></div> </el-carousel-item
              >
            </el-carousel>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="gifting-services__to-right">
              <h1 class="gifting-services__title">
                Plantable<br />
                greeting cards
              </h1>

              <p class="gifting-services__text">
                Our elegant custom-made greeting cards are handmade in small
                batches by Vita Beata Boutique, a small Canadian business
                focused on environmental sustainability. All cards are plantable
                - embedded with wildflower seeds and are paired with an elegant
                brown kraft paper envelope. Our cards are fully biodegradable,
                recyclable, non-toxic, plastic free, and made with 100%
                post-consumer recycled materials. To better suit our customer’s
                needs, our cards are gender and religion neutral. Each card is
                made with love, designed and hand-drawn or painted by the
                talented artist at Vita Beata Boutique. We hope you love these
                cards as much as we do!<br /><br />
                When adding one of our cards to an order, customers can choose
                the option of either a blank or personalized card. Blank cards
                will be delivered as is, with a kraft paper envelope. When
                personalizing a card, we will hand write your desired message
                inside the card and encase it in its envelope. When handwriting
                a personalized message in a card, we will do our best to match
                the customer’s desired formatting as displayed in the text box
                of the mobile app. Personalized cards being paired with a
                wrapped gift will be neatly attached to the gift for delivery.
              </p>
            </div>
          </el-col>
        </el-row>
      </section>
    </section>

    <app-footer />
  </div>
</template>

<script>
  import AppHeader from '../components/RoadrunnerLanding/AppHeader'
  import AppFooter from '../components/RoadrunnerLanding/AppFooter'

  export default {
    name: 'GiftingServices',

    components: {
      AppHeader,
      AppFooter,
    },

    data() {
      return {
        isDesktop: window.innerWidth >= 992
      }
    }
  }
</script>

<style lang="scss" scoped>
  .gifting-services {
    margin-top: 160px;
    box-sizing: border-box;
    text-align: left;
    font-family: 'CabinetGrotesk-Regular';

    @media (max-width: 991px) {
      max-width: 500px;
    }

    &__section {
      margin-bottom: 128px;

      @media (max-width: 991px) {
        margin-bottom: 78px;
      }
    }

    &__title {
      font-family: 'CabinetGrotesk-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 74px;
      color: #000000;
      max-width: 585px;
      margin-bottom: 42px;

      @media (max-width: 991px) {
        font-size: 36px;
        line-height: 45px;
        margin-top: 20px;
      }
    }

    &__text {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 21px;
      line-height: 42px;
      color: #000000;
      max-width: 500px;

      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 30px;
      }
    }

    &__to-right {
      @media (min-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
      }
    }

    &__download-buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      @media (max-width: 991px) {
        justify-content: flex-start;
      }
    }

    &__download {
      margin-top: 32px;
      margin-right: 8px;
      cursor: pointer;
      width: unset !important;

      @media (max-width: 1200px) {
        margin-top: 0px;
        width: 136px !important;
      }
    }

    &__center {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    img {
      border-radius: 2px;
      max-width: 100%;
    }
  }
</style>
